import React from "react";
import Container from "react-bootstrap/Container";
import { Card, Alert, Badge, Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

import updatedFeatureImg from "../updated-feature.png";

export default function ReleaseNotes() {
  const metaData = React.useContext(AppMetaDataContext);

  const [searchParams] = useSearchParams();
  const updated = searchParams.get("updated");

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <Helmet>
        <title>Updates - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div>
        <div className="" style={{ maxWidth: "700px" }}>
          <h1 className="display-4 mb-5">Updates</h1>
        </div>

        <div style={{ maxWidth: "700px" }}>
          {updated ? (
            <Alert variant="success">
              Your {metaData.appName} extension has been updated to the latest
              version!
            </Alert>
          ) : (
            <></>
          )}

          {/* <Image src={flashcards} fluid style={{ maxWidth: "300px" }} /> */}

          <Card border="success" className="my-3">
            <Card.Body>
              <Card.Title>
                <h3>
                  Get fluent faster with Lingo Champion{" "}
                  <sup>
                    <Badge bg="success">New!</Badge>
                  </sup>
                </h3>
              </Card.Title>
              <Card.Text>
                <p>
                  <strong>
                    Read the news in your target language and learn new words.
                  </strong>
                </p>
                <p>
                  I've just launched a new language learning app called called{" "}
                  <a href="https://lingochampion.com">Lingo Champion</a>. It's a
                  news reader that helps you learn new words in your target
                  language. Real news articles, real fluency.
                </p>
                <Button
                  variant="success"
                  className="mt-2 mb-4"
                  href="https://lingochampion.com"
                >
                  Check out Lingo Champion
                </Button>
                <p>
                  There are both free and paid plans. As a Duolingo Ninja user
                  you can use the{" "}
                  <strong>
                    <em>DUOLINGONINJA50</em>
                  </strong>{" "}
                  discount code to get 50% off for the first month.
                </p>

                <p>
                  Happy learning, <br />
                  Meelis
                  <br />
                  Duolingo Ninja, founder
                </p>
              </Card.Text>
            </Card.Body>
          </Card>

          <br />
          <br />

          <Card>
            <Card.Body>
              <Card.Title>20 May 2024</Card.Title>
              <Card.Text>
                <ul>
                  <li><strong>Fixed sentence translation bug</strong>
                    <ul>
                      <li>You can now choose which sentence language you're translating to.</li>
                      <li>This was an issue only for those who used the new sync.</li>
                    </ul>
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>6 April 2024</Card.Title>
              <Card.Text>
                <ul>
                  <li><strong>Sync workaround is now live</strong>
                    <ul>
                      <li>You can sync any language for which the Duolingo website has/shows the Word section.</li>
                      <li>You can choose between three different settings.</li>
                      <li>If the word already existed but had the wrong case ("Tochter" vs "tochter", for example), it will be corrected.</li>
                      <li>If you already had synced words and the automatic translations of the words was different from the Duolingo course language, the word translations might get overwritten. Be extra careful with the sync settings.</li>
                      <li>The extra info (strength, skills, last practiced date etc.) of your previously synced words won't get overwritten with the new sync. Only the translation (or possible word case) will be updated. </li>
                    </ul>
                  </li>
                  <li><strong>Translations with multiple word translation values are live. </strong> If you separate the word translations by comma, each comma-separated value will be checked against separately during translation.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>23 February 2024</Card.Title>
              <Card.Text>
                <ul>
                  <li>Bug fix for the website: unsubscription from the newsletter now works properly.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.32, released 6 February 2024</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    <strong>The extension now works in Opera.</strong>
                  </li>
                  <li>Fix voice synthesis bug in tooltips.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.31, released 6 February 2024</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Fix Cantonese pronunciation on flashcards and tooltips.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.30, released 5 February 2024</Card.Title>
              <Card.Text>
                <ul>
                  <li>Bug fixes to make the extension more stable.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.29, released 5 February 2024</Card.Title>
              <Card.Text>
                <ul>
                  <li>Bug fixes to make the extension more stable.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.28, released 30 January 2024</Card.Title>
              <Card.Text>
                <ul>
                  <li>Bug fixes to make the extension more stable.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.27, released 5 January 2024</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Change feedback and bug report forms to a new provider.
                  </li>
                  <li>
                    Change{" "}
                    <a href="https://lingochampion.com">Lingo Champion</a> ads.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.26, released 2 January 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Force registration during extension update if the
                    registration wasn't successful before.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.25, released 25 December 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>Bug fixes to make the server more stable.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.24, released 30 November 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    AI-generated sample sentences in flashcards is now a Premium
                    plan feature.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.23, released 27 November 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>Redesigned the options page to make it easier to use.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.22, released 27 November 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Fixed a bug when syncing multiple languages from Duolingo -
                    it now works correctly and asks you whether you want to
                    overwrite or not.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.21, released 24 November 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Sync used to override existing translations (unless these
                    were edited translations). It doesn't anymore. This will
                    help you save time and character limit while syncing.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.20, released 11 November 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Modified the{" "}
                    <a href="https://lingochampion.com">Lingo Champion</a> ad.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.19, released 4 November 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Added a message about beta access to a new language learning
                    app.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.18, released 13 October 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Corrected Duolingo word strength options (they go from 1-4
                    not from 0-5).
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.17, released 12 October 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Fixed a bug where the error message for the Japanese wasn't
                    displayed.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.16, released 12 October 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    It's now possible to get the vocabulary of unsupported
                    languages (Klingon, High Valyrian, Latin, Navajo, Yiddish,
                    Gaelic, Esperanto, Guarani) but without translations and any
                    other extra information.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.15, released 11 October 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>Fixed the uninstall URL.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.14, released 11 October 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Fixed the bug where some tooltips didn't display the
                    translated word in white text.
                  </li>
                  <li>Fixed some wording on the options page.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.13, released 11 October 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    More links are now ignored for translations if the setting
                    is turned on (especially relevant to the users of Notion).
                  </li>
                  <li>
                    Corrected the wording of the extension enabled/disabled
                    bubble.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.12, released 5 October 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Display Bilingual News in Spanish podcast announcement in
                    the settings if the user is learning Spanish.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.11, released 1 October 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Reduced translation tracking events (for dynamically loaded
                    content) to reduce server load and request failures. Some
                    Premium users didn't see themselves on the Premium plan,
                    this should be fixed now.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                Version 1.6.10, released 28 September 2023
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Word translations can now be edited.</li>
                  <li>
                    Flashcard translations can now be edited when using
                    flashcards.
                  </li>
                  <li>
                    Fixed a bug where the helper text on flashcards was displaed
                    wrong when the sample sentence was present.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.9, released 26 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>Maintenance release.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.8, released 25 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Fixed a bug where the freemium registration might bring the
                    server down.
                  </li>
                  <li>
                    Fixed another bug that didn't display the new Premium
                    feature correctly.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.7, released 25 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Update word strength, last practiced date automatically when
                    opening options.
                  </li>
                  <li>
                    <strong>
                      Let users filter out weak words when translating words
                    </strong>{" "}
                    (on Premium plan only).
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.6, released 23 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Improve the words copy button in the Duolingo words table.
                  </li>
                  <li>Change tooltip color to a lighter black.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.5, released 23 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Fix the bug where the speech synthesis didn't work in
                    tooltips.
                  </li>
                  <li>
                    Redesign the tooltips a bit and add links to feedback and
                    settings.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.4, released 23 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Word translations should be much faster now on pages with
                    loads of dynamically loaded contents. But you might see also
                    less translations depending on the page.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.3, released 22 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>Changed the feedback form after uninstall.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.2, released 22 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    A modal is shown after the first sync to explain how to use
                    the extension.
                  </li>
                  <li>Specify premium plan text near the word counter.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.1, released 22 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Editable content is now ignored for translations. A lot of
                    them were ignored already (code blocks, text boxes etc.) but
                    not the HTML elements with editable-content attribute.
                  </li>
                  <li>Added auto-speech monitoring for better debugging.</li>
                  <li>Some minor corrections to the options page.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.6.0, released 20 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    <strong>
                      Words can now be translated also in dynamically loaded
                      content.
                    </strong>{" "}
                    This setting is turned on by default and can be changed in
                    the settings.
                  </li>
                  <li>
                    Fixed a bug where the settings under "How to translate"
                    could only be saved when clicking the "Save" button under
                    "Ingored domains".
                  </li>
                  <li>
                    Removed the "Spread translations" experimental feature. It
                    still needs some work before it can be reliably released.
                  </li>
                  <li>Several little bug fixes.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.5.0, released 17 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>You can now hear pronunciation in the tooltips.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                Version 1.4.20, released 16 September 2023
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Added an error message for Esperanto.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                Version 1.4.19, released 16 September 2023
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Clarified flashcards link in the popup.</li>
                  <li>
                    Added a direct link to the "forgot password" page in the
                    login form.
                  </li>
                  <li>Specified flashcard strength explainer texts.</li>
                  <li>Added a new explainer section under sync.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                Version 1.4.18, released 15 September 2023
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Cleaned up unnecessary code to make the extension faster.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                Version 1.4.17, released 15 September 2023
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Flashcards are now sorted based on the sum of Duolingo word
                    strength and Duolingo Ninja flashcard strength.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                Version 1.4.16, released 15 September 2023
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Simplified the second sync step - it now shows you which
                    language is selected in Duolingo.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                Version 1.4.15, released 14 September 2023
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Added a button to simplify copying words from the Duolingo
                    words table to the clipboard.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                Version 1.4.14, released 14 September 2023
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Fix a bug where the extension would show synced words number
                    although no words were synced.
                  </li>
                  <li>
                    Show total number of words that could be synced from
                    Duolingo.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                Version 1.4.13, released 14 September 2023
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Added word strength and last_practiced columns to the words
                    table as premium features.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                Version 1.4.12, released 14 September 2023
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Removed YouTube Summarizer experimental feature.</li>
                  <li>
                    Added an auto-speech feature for Flashcards. It's turned on
                    by default. You can turn it off in the settings.
                  </li>
                  <li>
                    Added feedback info to the back of the results card in
                    Flashcards.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                Version 1.4.11, released 14 September 2023
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Fix Chinese (Traditional) syncing problem.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                Version 1.4.10, released 13 September 2023
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Added speech synthesis support for flashcards for the most
                    common languages.
                  </li>
                  <li>
                    Flashcards words are now properly randomized (the same word
                    is not displayed twice in a row).
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.4.9, released 13 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>Added error message for Yiddish.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.4.8, released 13 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>Added support for Dutch.</li>
                  <li>
                    Added error messages for Klingon, High Valyrian, Latin, and
                    Navajo.
                  </li>
                  <li>
                    Added error logging for faster bug detection and fixing.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.4.7, released 12 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Add shortcuts behind the words to practice the skills with
                    flashcards.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.4.6, released 12 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Flashcards can be practiced based on the Duolingo skills
                    now.
                  </li>
                  <li>Show skills in the words table.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.4.5, released 9 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    If the word is guessed right with flashcard the first time,
                    it won't be shown again during the same session.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.4.4, released 9 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Added a failsafe for flashcard sample sentences generated by
                    the AI.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.4.3, released 9 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Feedback links now contain the user ID for easier/faster bug
                    detection.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.4.2, released 9 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Flashcards now use AI-generated sample sentences to provide
                    you with more context.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.4.1, released 7 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Users can now open flashcards in a new tab from the Duolingo
                    Ninja popup.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.4.0, released 7 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Added a new experimental feature -{" "}
                    <strong>flashcards</strong>. You can now create flashcards
                    from the words you've learned on Duolingo. You can access it
                    through the settings.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.3.2, released 4 September 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Added a setting for changing the background color of the
                    translations.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.3.1, released 30 August 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Fixed a bug where Chinese learners couldn't sync the words.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.3.0, released 28 August 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Added a new experimental feature -{" "}
                    <strong>YouTube summarizer</strong>. You can now summarize
                    YouTube videos in the language you're learning. The feature
                    is turned on by default. You can change it in the Duolingo
                    Ninja settings.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.2.4, released 25 August 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Tips for using Duolingo Ninja are now shown under some word
                    translations.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.2.3, released 24 August 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Automatic translations can now be edited in the settings.
                    The edied translations won't be overridden by the automatic
                    translations when syncing.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.2.2, released 11 August 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>Added review notifications.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.2.1, released 8 August 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Switched the translation service to Microsoft to ensure
                    better precision (also for syncing).
                  </li>
                  <li>
                    The list of languages has been updated as well and sorted
                    alphabetically.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.2.0, released 4 August 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Tooltip showing logic has been improved greatly. This fixed
                    many bugs on websites that use Bootstrap as their design
                    framework.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.1.0, released 25 July 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Re-organized settings to make the Duolingo syncing process
                    clearer.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 1.0.0, released 17 July 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The extension now shows an error message if it can't connect
                    to the server.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 0.9.9, released 16 July 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The extension doesn't crash anymore when the translation
                    server is unreachable for some reason.
                  </li>
                  <li>
                    Fixed a bug where some Premium plan users didn't have access
                    to the paid features.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 0.9.8, released 4 July 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Sync now shows how many words there potntially are to sync.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 0.9.7, released 1 July 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Minor fixes to word translation inner workings for better
                    debugging.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 0.9.6, released 30 June 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Fixed a bug where the extension would not work if the user
                    was logged out of Chrome.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 0.9.4, released 29 June 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Added error message when the Duolingo learning language is
                    the same as the language for translating those Duolingo
                    words to.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 0.9.3, released 28 June 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Fixed a bug where syncing Duolingo immediately after
                    installation resulted in an error message saying that
                    there's no JWT token.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 0.9.2, released 27 June 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>Fixed a bug related to checking cookies.</li>
                  <li>Fixed a bug for getting languages.</li>
                  <li>Made some small fixes to the development mode.</li>
                  <li>
                    Opening up options after installation now instead of the
                    success page.
                  </li>
                  <li>Increased the free limit to 20,000 chars.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>Version 0.9.1, released 26 June 2023</Card.Title>
              <Card.Text>
                <ul>
                  <li>Released private beta.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
        </div>
      </div>
    </Container>
  );
}
